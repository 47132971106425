.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login {
  background-color: white;
  padding: 30px;
  gap: 20px;
  text-align: center;
  max-width: 450px;
  width: inherit;
  height: auto;
  /* box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px;
}

.formElement {
  display: flex;
  flex-direction: column;
  text-align: start;
  width: 100%;
  text-decoration: none;
}

.heading {
  color: #3c3d42;
  font-weight: 600;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.label {
  color: #6b7280;
  font-weight: 600;
  font-size: 0.875em;
  margin: 1px;
  margin-bottom: 5px;
  font-weight: 400;
}